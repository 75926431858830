
import './App.css';
import { Swiper as SwiperElement, SwiperSlide } from 'swiper/react';
import Swiper, { Navigation } from "swiper";

import 'swiper/css';
import { useState } from 'react';

function App() {

  const [actualDateIndex, setActualDateIndex] = useState(0);
  const [swiper, setSwiper] = useState<Swiper>();

  const list = [
    {
      date: 2022,
      content: <>
        <div>
          SIES Salud decide continuar con las estrategias de los 5 objetivos de desarrollo sostenible definidas en el 2021 e incorporar nuevas estrategias enfocadas en el trabajo con comunidades y en desarrollar su proyecto emblemático.

          <br />
          <br />

          <strong>Durante este año</strong> seguimos dedicando esfuerzos en la interoperabilidad, interoperando con aliados estratégicos externos como nuestro aliado de laboratorios así como con algunos aseguradores, adicionalmente actualmente estamos en proyecto para integrarnos con la cuenta de alto costo.

          <br />
          <br />

          <strong>Cerramos el año 2022 con un</strong> portafolio de productos fortalecido por 7 programas de atención (VIH, Hepatitis C, ERC, autoinmunes, cuidados paliativos, pluripatologia)      enfocados en la Gestión del Riesgo, mediante la Identificación y el análisis de los factores, formula estrategias de intervención y priorización de grupos de riesgo. Esto le permite una administración pertinente del costo médico del asegurador y una red integrada de IPS con estándares superiores.

          <br />
          <br />

          La experiencia de SIES Salud se acredita  con sus 16 años de operación,  las 24 IPS propias en ciudades principales del país y las tres ciudades adicionales cubiertas bajo brigadas de salud.

          <br />
          <br />

          Seguiremos construyendo futuro, por el bienestar de nuestros usuarios.
        </div>
      </>
    },
    {
      date: 2021,
      content: <>
        <div>Se ejecutaron proyectos de transformación digital para las diferentes células apoyando así los procesos transversales de la organización, algunos de los más importante son Agendamiento inteligente, gestión documental, el CRM, firma digital, cero papel y la adquisición del bus de integración desde se vienen construyendo diferentes servicios a la fecha para que las aplicaciones interoperen entre sí</div>
      </>
    },
    {
      date: 2021,
      content: <>
        <div>
          SIES Salud en su intención de maximizar su crecimiento y contar con el apalancamiento financiero que permitiera este objetivo, cierra una importante transacción con <strong>LINZOR CAPITAL PARTNERS</strong> como fondo de inversión.

          <br />
          <br />

          Este año, recibimos por parte de la cuenta de alto costo (CAC), nuestra primera nominación en el programa de Enfermedad Renal Crónica.

          <br />
          <br />

          <strong>Para este mismo año</strong>  SIES Salud consciente de la necesidad de trabajar por el desarrollo sostenible de la organización ,  decide  iniciar acciones transformadoras enfocadas en <strong>5 objetivos de desarrollo sostenible como son :</strong>
          <br />
          <br />

          <ul>
            <li>Salud y bienestar</li>
            <li>equidad de género,</li>
            <li>reducción de desigualdades,</li>
            <li>consumo y producción responsables,  y</li>
            <li>acción por el clima.</li>
          </ul>

          <br />
          <br />

          Para cumplir con nuestros objetivo de desarrollo sostenible desarrollamos las siguientes acciones durante este año:

          <br />
          <br />

          <strong>
            Hábitos saludables y prevención de enfermedades
            <br />
            Diversidad, equidad e inclusión
            <br />
            Paneles Solares
            <br />
            Cero Papel
          </strong>

          <br />
          <br />

          Al interior de  SIES  se lleva a cabo un plan de   reconocimiento de premiación y  ranking con nuestras IPS, que busca generar expectativa y reto en las unidades de salud a estar nominadas en un evento a nivel nacional que exalta los mejores resultados de salud

        </div>
      </>
    },
    {
      date: 2020,
      content: <>
        <div>
          la pandemia representó un reto para todo el sector, en donde SIES transformó su modelo operativo en los siguientes componentes:

          <br />
          <br />

          <ul>
            <li>Inició la atención 100% no presencial haciendo uso de la telemedicina y teleasistencia</li>
            <li>Entregó los medicamentos en el domicilio de los pacientes sin que esto representara un costo adicional para el asegurador</li>
            <li>Amplió los horarios de atención de todos sus canales, con el propósito de facilitar la comunicación, gestión de citas y lograr resolver las diferentes solicitudes que durante esta época se triplicaron</li>
            <li>SIES también implementó la toma de muestras de laboratorio en el domicilio de los pacientes, así como actividades de toma de peso, medidas y otras intervenciones que para ciertos programas y pacientes eran fundamentales</li>
            <li>Para lograr lo anterior caracterizó a los usuarios identificando con qué herramientas de comunicación contaba (Teléfono inteligente, tabletas, computadores, acceso a internet) de manera directa o a través de algún contacto cercano, esto permitió adaptar la atención a los recursos realmente disponibles de los pacientes</li>
            <li>Así mismo, SIES capacitó a todo su equipo asistencial en las nuevas herramientas disponibles y llevó a cabo procesos de acompañamiento directo para fortalecer la humanización del servicio, empatía, comunicación con los pacientes, entre otros elementos</li>
          </ul>

          <br />
          <br />

          Lo anterior permitió que la atención se mantuviera, logrando un  incrementó en  la asistencia y participación de los pacientes en las diferentes actividades.
          los resultados en salud mejoraron durante este periodo, así como las coberturas de los diferentes componentes de los modelos de atención.

          <br />
          <br />

          Implementamos cambios e Innovamos en nuestra estructura organizacional, nuestras herramientas de transformación digital, con un propósito de ser más competitivos  en nuestra generación de valor .

          <br />
          <br />

          Durante este año culminamos  exitosamente el diseño y desarrollo del programa Autoinmunes.

        </div>
      </>
    },
    {
      date: 2019,
      content: <>
        <div>
          SIES fue nominada por la Cuenta de Alto Costo (CAC), entidad que vigila a los aseguradores y prestadores en cuanto a la calidad de la información de los  resultados en salud, en este caso en VIH, nominados en ese año con  4 de nuestras IPS,  resultando  ganadoras tres de ellas.(Armenia, Popayán y Valledupar) 
          <strong>Este mismo año</strong>, se logra  el primer contrato para  inicio de operaciones con <strong>SURA EPS</strong>, para nuestro programa de pacientes con VIH.

          <br />
          <br />

          Este mismo año se diseña y desarrolla el programa de Pluripatología, con el objetivo de suplir necesidades del mercado.


        </div>
      </>
    },
    {
      date: 2018,
      content: <>
        <div>
          Se diseña y desarrolla el programa de cuidado paliativo para cubrir necesidades de los aseguradores y  de esta manera complementar nuestra  oferta de productos.
        </div>
      </>
    },
    {
      date: 2017,
      content: <>
        <div>
          Dando respuesta a la necesidad del mercado SIES incorpora en su portafolio de productos el programa de Hepatitis C.
        </div>
      </>
    },
    {
      date: 2015,
      content: <>
        <div>
          Aperturamos una IPS de Puerta de Entrada exclusiva para la atención de los pacientes de COMPENSAR en Bogotá, bajo el Programa Familia saludable.
          <br />
          <br />
          En este mismo año  ampliamos nuestro  modelo de negocio con el modelos de <strong>atención domiciliaria</strong> en las ciudades de Antioquia y Eje Cafetero.
        </div>
      </>
    },
    {
      date: 2014,
      content: <>
        <div>
          Diversificamos nuestro portafolio y lanzamos a nuestros clientes el programa de nefro protección llamado Nefrovida enfocado en la atención de pacientes con enfermedad renal crónica.
          <br />
          <br />
          <strong>En este mismo año cerramos nuestra</strong> negociación con SANITAS.

        </div>
      </>
    },
    {
      date: 2007,
      content: <>
        <div>

          Durante el periodo de los años comprendidos entre el <strong>2007 al 2013</strong> , SIES se consolido con 12 IPS para la prestación de servicios en la que  consolido <strong>Programas Integrales de salud  en enfermedades trasmisibles (VIH)</strong>.
          
          <br />
          <br />
          <strong>Así mismo, en su visión de diversificar su portafolio inicio el desarrollo de nuevos modelos</strong> de enfermedades crónicas no transmisibles, respaldados en su Modelo <strong>de Atención</strong> que permitiera lograr un impacto positivo en la Triple Meta plus.

        </div>
      </>
    },
    {
      date: 2006,
      content: <>
        <div>
          <strong>Con el fin de cubrir algunas necesidades del mercado nace en Noviembre  del 2006 SIES Salud, Sociedad Integral de Especialistas en Salud</strong>.,por un grupo de empresarios en la ciudad de Bogotá con el fin de impactar de manera positiva la salud de las personas que vivían con VIH  permitiéndoles recibir  una atención integral en un solo lugar en las ciudades de Armenia, Pereira y Cali.
        </div>
      </>
    }
  ];

  const changeDate = (index: number) => {
    setActualDateIndex(index);
    swiper?.slideTo(index);

  }

  const onChangeSwiper = (index: number) => {
    setActualDateIndex(index);
  }

  const setClass = (index: number): string => {

    let classDate = 'no-show';

    if (index === actualDateIndex) {
      classDate = 'actual-date'
    }

    if (index === actualDateIndex - 2) {
      classDate = 'prev-prev-date'
    }

    if (index === actualDateIndex - 1) {
      classDate = 'prev-date'
    }

    if (index === actualDateIndex + 1) {
      classDate = 'next-date'
    }

    if (index === actualDateIndex + 2) {
      classDate = 'next-next-date'
    }

    return classDate;
  }


  return (
    <div className="App">

      <div className="content">

        <ul className="dates">
          {
            list.map((x, index) => <li key={index} onClick={() => { changeDate(index) }} className={`date ${setClass(index)}`}>
              {x.date}
            </li>)
          }
        </ul>

        <div className="carrousel">
          <div className="actions">
            <button type='button' className='button-prev'></button>
            <button type='button' className='button-next'></button>
          </div>

          <SwiperElement
            autoHeight={true}
            modules={[Navigation]}
            slidesPerView={1}
            onSlideChange={(swiper) => onChangeSwiper(swiper.activeIndex)}
            navigation={
              { nextEl: '.button-next', prevEl: '.button-prev' }
            }
            onSwiper={(swiper) => {
              setSwiper(swiper)
            }}>
            {
              list.map((x, index) => <SwiperSlide key={index}>{x.content}</SwiperSlide>)
            }
          </SwiperElement>

        </div>
      </div>


    </div>
  );
}

export default App;
